import React, { useState } from 'react'
import {getSettingsRequest, postSettingsRequest} from './requests'
import { toast } from 'react-toastify'

export default function Settings(props) {
  const [liquidationSettings, setLiquidationSettings] = useState(null)
  const [readonly, setReadOnly] = useState(null)
  const [exchangeReadOnlyFromPrimary, setExchangeReadOnlyUsingPrimary] = useState(null)
  const [exchangePostOnlyModeFromPrimary, setExchangePostOnlyModeUsingPrimary] = useState(null)
  const [applicationPostOnlyModeFromPrimary, setApplicationPostOnlyModeUsingPrimary] = useState(null)
  const [secondaryAndProxyApplicationPostOnlyModeFromPrimary, setSecondaryAndProxyApplicationPostOnlyModeUsingPrimary] = useState(null)
  const [exchangeConfig, setExchangeConfig] = useState(null)

  return (
    <div>
      <h3>Settings and State</h3>
      <div>Liquidation Settings</div>
      <div>
        <button onClick={() => getRequestAndSet('liquidation', setLiquidationSettings)}>
          Fetch
        </button>
        <button onClick={() => toggleLiquidation(true)}>
          Enable Liquidation Runner
        </button>
        <button onClick={() => toggleLiquidation(false)}>
          Disable Liquidation Runner
        </button>
      </div>
      <div>{JSON.stringify(liquidationSettings)} </div>
      <div>---</div>
      <h3>Readonly Configurations Using Primary</h3>

      <b>Application Configured Read only status</b>
      <div>
        <div>
          <button onClick={() => getRequestAndSet('readonly', setReadOnly)}>
            Fetch
          </button>
          <button onClick={() => toggleReadOnly(true)}>
            Enable Application ReadOnly
          </button>
          <button onClick={() => toggleApplicationReadOnlyWithConfirmationIfNotInPostOnlyMode(false)}>
            Disable Application ReadOnly
          </button>
        </div>
        <div>{JSON.stringify(readonly)} </div>
      </div>
      <br/>
      <b>Exchange Configured Read only Configs Values</b>
      <div>
        <button onClick={() => getRequestAndSet('exchange-read-only-config', setExchangeReadOnlyUsingPrimary)}>
          Fetch
        </button>
        <div>Configs: {JSON.stringify(exchangeReadOnlyFromPrimary)}</div>
      </div>
      <br/>
      <b>Exchange Configured Read only status</b>
      <div>
        <button onClick={() => toggleExchangeReadOnlyOnAdmin(true)}>
          Enable Exchange ReadOnly
        </button>
        <button onClick={() => toggleExchangeReadOnlyWithConfirmationIfNotInPostOnlyMode(false)}>
          Disable Exchange ReadOnly
        </button>
      </div>
      <br/>
      <b>Exchange Configured GoIntoReadOnlyModeOnResume</b>
      <div>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeOnAdmin(true)}>
          Enable Exchange GoIntoReadOnlyModeOnResume
        </button>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeOnAdmin(false)}>
          Disable Exchange GoIntoReadOnlyModeOnResume
        </button>
      </div>
      <br/>
      <div>---</div>
      <h3>Global Post-Only (Using Primary)</h3>
      <b>Exchange: Global Post-Only Mode</b>
      <div>
        <button onClick={() => getRequestAndSet('exchange-global-post-only-mode', setExchangePostOnlyModeUsingPrimary)}>
          Fetch
        </button>
        <div>Current: {JSON.stringify(exchangePostOnlyModeFromPrimary)}</div>
      </div>
      <div>
        <button onClick={() => toggleExchangeGlobalPostOnly(true)}>
          Enable Exchange Global Post-only Mode
        </button>
        <button onClick={() => toggleExchangeGlobalPostOnly(false)}>
          Disable Exchange Global Post-only Mode
        </button>
      </div>
      <br/>
      <br/>
      <b>Application: Global Post-Only Mode</b>
      <div>
        <div>
          <div>
            <button onClick={() => getRequestAndSet('application-global-post-only-mode', setApplicationPostOnlyModeUsingPrimary)}>
              Fetch Primary
            </button>
            <button onClick={() => getRequestAndSet('secondaries-and-proxies-application-global-post-only-mode', setSecondaryAndProxyApplicationPostOnlyModeUsingPrimary)}>
              Fetch Secondaries + Proxies
            </button>
            <div>Current Primary: {JSON.stringify(applicationPostOnlyModeFromPrimary)}</div>
            <div>Current Secondaries + Proxies: {JSON.stringify(secondaryAndProxyApplicationPostOnlyModeFromPrimary)}</div>
          </div>
          <button onClick={() => toggleApplicationGlobalPostOnly(true)}>
          Enable Application Global Post-Only Mode
          </button>
          <button onClick={() => toggleApplicationGlobalPostOnly(false)}>
            Disable Application Global Post-Only Mode
          </button>

          <button onClick={() => reloadSecondariesAndProxiesApplicationGlobalPostOnly()}>
            Reload Secondaries' + Proxies' Global Post-Only Mode
          </button>
        </div>
      </div>
      <br/>
      <div>---</div>

      <div>Exchange Config</div>
      <div>
        <div>
          <button onClick={() => getRequestAndSet('config', setExchangeConfig)}>
            Fetch
          </button>
          <button onClick={() => toggleCrossAccountTradingDisabled(false)}>
            Enable Cross Account Trading
          </button>
          <button onClick={() => toggleCrossAccountTradingDisabled(true)}>
            Disable Cross Account Trading
          </button>
        </div>
      </div>
      <div>{JSON.stringify(exchangeConfig)} </div>
      <div>---</div>
    </div>
  )
}

const toggleReadOnly = async enabled => {
  const result = await postSettingsRequest('readonly', {enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleApplicationReadOnlyWithConfirmationIfNotInPostOnlyMode = async enabled => {
  const applicationGlobalPostOnlyModeResult = await getSettingsRequest('application-global-post-only-mode')

  if (applicationGlobalPostOnlyModeResult.applicationGlobalPostOnlyMode === 'false') {
    const userConfirmed = window.confirm('Application Global Post-Only mode is currently DISABLED.\n\nDo you want to proceed ?')

    if (!userConfirmed) {
      return
    }
  }
  return toggleReadOnly(enabled)
}

const toggleExchangeReadOnlyOnAdmin = async enabled => {
  const result = await postSettingsRequest('exchange-readonly', { value: enabled })
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeReadOnlyWithConfirmationIfNotInPostOnlyMode = async enabled => {
  const exchangeGlobalPostOnlyModeResult = await getSettingsRequest('exchange-global-post-only-mode')

  if (exchangeGlobalPostOnlyModeResult.exchangeGlobalPostOnlyMode === 'false') {
    const userConfirmed = window.confirm('Exchange Global Post-Only mode is currently DISABLED.\n\nDo you want to proceed?')

    if (!userConfirmed) {
      return
    }
  }
  return toggleExchangeReadOnlyOnAdmin(enabled)
}

const toggleExchangeGoIntoReadOnlyModeOnResumeOnAdmin = async enabled => {
  const config = 'GO_INTO_READONLY_MODE_ON_RESUME'
  const result = await postSettingsRequest('config/booleanconfig', {config, enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}
const toggleApplicationGlobalPostOnly = async enabled => {
  const result = await postSettingsRequest('application-global-post-only-mode', {enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const reloadSecondariesAndProxiesApplicationGlobalPostOnly = async enabled => {
  const result = await postSettingsRequest('reload-secondaries-and-proxies-application-global-post-only-mode', {enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeGlobalPostOnly = async enabled => {
  const result = await postSettingsRequest('exchange-global-post-only-mode', {enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleCrossAccountTradingDisabled = async enabled => {
  const config = 'CROSS_ACCOUNT_TRADING_DISABLED'
  const result = await postSettingsRequest('config/booleanconfig', {config, enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleLiquidation = async enabled => {
  const result = await postSettingsRequest('liquidation', { enabled })
  if (!result.error) toast.success('Success. Please fetch again')
}

const getRequestAndSet = async (url, set) => {
  set(null)
  const result = await getSettingsRequest(url)
  set(result)
}
