import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Container, Table, TableBody, TableRow, TableCell, TableHeader } from './styles'
import getInstance from 'helpers/axios'
import { toast } from 'react-toastify'

const Slippage = () => {
  const [globalInfo, setGlobalSlippageInfo] = useState([])
  const [currencyPair, setCurrencyPair] = useState()
  const [optionsValue, setOptionsValues] = useState('')
  const [customSlippageInfo, setCustomSlippageInfo] = useState([])

  const countDecimals = function (value) {
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0; 
}

  const setSlippage = async (pairType, orderType, current, pair) => {
    let newValue = prompt('newValue', current)
    if (newValue == null || newValue >= 1 || newValue <= 0 || isNaN(newValue)) {
      alert(`Cancelled due to incorrect value: ${newValue}`)
      return
    }

    let decimalCount = countDecimals(newValue)
    if(decimalCount < 1 || decimalCount > 2) {
      alert(`Cancelled because value ${newValue} has too many decimal places ${decimalCount}`)
      return
    }

    if (newValue == current) {
      alert('Cancelled because values are unchanged')
      return
    }

    let accepted = prompt(
      `Setting ${pairType} ${orderType} slippage from ${current} to ${newValue}(${(newValue * 100).toFixed(0)}%) for ${
        pair != null ? pair : 'Global'
      }. CONTINUE?`,
      'OK',
    )
    if (accepted == null || accepted != 'OK') {
      alert('Cancelled')
      return
    }

    try {
      const res = await getInstance().post(`/api/slippage`, {
        pairType,
        orderType,
        newValue,
        pair,
      })
      toast.success(`Success please fetch again if data doesn't show up: ${res.data}`)
      getSlippage()
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const clearSlippage = async pair => {
    let accepted = prompt(`Clearing custom slippage for ${pair}. CONTINUE?`, 'OK')
    if (accepted == null || accepted != 'OK') {
      alert('Cancelled')
      return
    }

    try {
      const res = await getInstance().post(`/api/slippage/clear`, {
        pair,
      })
      toast.success(`Success please fetch again if data doesn't show up: ${res.data}`)
      getSlippage()
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const getCurrencyPairs = async () => {
    try {
      const res = await getInstance().get('/api/currency/pairs')
      const { data } = res
      const availableCurrencyPairs = data
        .filter(currencyPair => {
          return currencyPair.active
        })
        .map(currencyPair => ({
          value: currencyPair.symbol,
          label: currencyPair.symbol,
          currencyPairType: currencyPair.currencyPairType,
        }))
      setOptionsValues(availableCurrencyPairs)
    } catch (error) {
      return error
    }
  }

  const getSlippage = async () => {
    try {
      const res = await getInstance().get('/api/slippage')
      const { data } = res
      const globalInfo = {
        spotMarket: data.maxMarketOrderSlippageFraction,
        spotLimit: data.maxSpotLimitOrderSlippageFraction,
        futureLimit: data.maxFutureOrderSlippageFraction,
      }
      const customSlippageInfo = data.customSlippageFractions

      console.log(data)

      setGlobalSlippageInfo(globalInfo)
      setCustomSlippageInfo(customSlippageInfo)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getCurrencyPairs()
    getSlippage()
  }, [])

  return (
    <Container>
      <div>
        <button onClick={() => getSlippage()}>Refresh Slippage Info</button>
        <div>
          <h3>Global Slippage</h3>
          <div>&nbsp;</div>
          <Table>
            <TableHeader>
              <TableCell flex={0.2}> Spot Market Fraction </TableCell>
              <TableCell flex={0.2}> Spot Limit Fraction </TableCell>
              <TableCell flex={0.3}> Future Limit Fraction </TableCell>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell flex={0.2}>
                  {globalInfo.spotMarket}
                  <button
                    onClick={() => {
                      setSlippage('SPOT', 'MARKET', globalInfo.spotMarket, null)
                    }}
                  >
                    Set
                  </button>
                </TableCell>
                <TableCell flex={0.2}>
                  {' '}
                  {globalInfo.spotLimit}{' '}
                  <button
                    onClick={() => {
                      setSlippage('SPOT', 'LIMIT', globalInfo.spotLimit, null)
                    }}
                  >
                    Set
                  </button>
                </TableCell>
                <TableCell flex={0.2}>
                  {' '}
                  {globalInfo.futureLimit}{' '}
                  <button
                    onClick={() => {
                      setSlippage('FUTURE', 'LIMIT', globalInfo.futureLimit, null)
                    }}
                  >
                    Set
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div>
          <div>
            <h3>Custom Slippage</h3>
            <Select options={optionsValue} placeholder={'Select currency pair'} onChange={e => setCurrencyPair(e)} />
            <button
              onClick={() =>
                currencyPair != null ? setSlippage(currencyPair.currencyPairType, 'LIMIT', '', currencyPair.value) : ''
              }
            >
              SET LIMIT
            </button>
            <div>&nbsp;</div>
            <button
              onClick={() =>
                currencyPair != null && currencyPair.currencyPairType != 'FUTURE'
                  ? setSlippage(currencyPair.currencyPairType, 'MARKET', '', currencyPair.value)
                  : ''
              }
            >
              SET MARKET (Only spot)
            </button>
          </div>
          <div>&nbsp;</div>
          <h4>Existing Custom Slippage</h4>
          <Table>
            <TableHeader>
              <TableCell flex={0.2}> Pair </TableCell>
              <TableCell flex={0.2}> Limit Fraction </TableCell>
              <TableCell flex={0.3}> Market Fraction </TableCell>
            </TableHeader>
            <TableBody>
              {customSlippageInfo &&
                customSlippageInfo.map(p => {
                  return (
                    <TableRow>
                      <TableCell flex={0.2}>
                        {p.currencyPair}({p.currencyPairId})
                      </TableCell>
                      <TableCell flex={0.2}>{p.limitOrderSlippageFraction}</TableCell>
                      <TableCell flex={0.3}>{p.marketOrderSlippageFraction}</TableCell>
                      <TableCell flex={0.3}>
                        <button onClick={() => clearSlippage(p.currencyPair)}>Clear Custom Slippage</button>{' '}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    </Container>
  )
}

export default Slippage
